import React from "react";

import Layout from "../components/layout";
import BCCDemoContact from "../components/BCCDemoContact";
import SEO from "../components/seo";

const BCCDemoContactPage = () => (
    <Layout>
        <SEO title="Schedule a Demo" />
        <section className="hero is-primary is-fullheight-with-navbar">
            <div className="hero-body">
                <div className="container">
                    <div className="columns">
                        <div className="column is-half">
                            <h1 className="title has-text-warning">Welcome, BCC2020 participant!</h1>
                            <h2 className="subtitle">
                                We&apos;d love to give you a demo. Send us a message and we will be in touch shortly.
                            </h2>
                        </div>
                        <div className="column">
                            <div className="container has-text-centered">
                                <BCCDemoContact />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
);

export default BCCDemoContactPage;
